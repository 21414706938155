// stores/toastStore.js
import { defineStore } from 'pinia';

export const useToastStore = defineStore('toastStore', {
  state: () => ({
    toastMessage: '',
    messageType: '',
  }),
  actions: {
    setToastMessage(message:string, type:string) {
      this.toastMessage = message;
      this.messageType = type;
    },
    clearToastMessage() {
      this.toastMessage = '';
      this.messageType = '';
    }
  },
});
